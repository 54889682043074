// import Image from "next/image"
// import Link from "next/link"
import {
    Form,
    Link,
    Outlet,
    useMatches,
    useRouteLoaderData,
    useSubmit,
} from '@remix-run/react'
import {
    ChevronLeft,
    ChevronRight,
    Copy,
    CreditCard,
    File,
    Home,
    LineChart,
    ListFilter,
    MoreVertical,
    Package,
    Package2,
    PanelLeft,
    Search,
    Settings,
    ShoppingCart,
    Truck,
    Users2,
    LockKeyhole,
    LogOut
} from "lucide-react"

import { Badge } from "#app/components/ui/badge.tsx"
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "#app/components/ui/breadcrumb.tsx"
import { Button } from "#app/components/ui/button.tsx"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "#app/components/ui/card.tsx"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "#app/components/ui/dropdown-menu.tsx"
import { Input } from "#app/components/ui/input.tsx"
// import {
//   Pagination,
//   PaginationContent,
//   PaginationItem,
// } from "#app/components/ui/pagination"
// import { Progress } from "#app/components/ui/progress"
import { Separator } from "#app/components/ui/separator.tsx"
import { Sheet, SheetContent, SheetTrigger } from "#app/components/ui/sheet.tsx"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "#app/components/ui/table.tsx"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "#app/components/ui/tabs.tsx"
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
    TooltipProvider
} from "#app/components/ui/tooltip.tsx"
import { Avatar } from '@radix-ui/react-avatar'
import { AvatarFallback, AvatarImage } from '#app/components/ui/avatar.tsx'
import { useRef } from 'react'
import { cn } from '#app/utils/misc.tsx'
import { type loader as rootLoader } from '#app/root.tsx'

function linkIsActive(matches: Array<any>, id: string) {
    return matches.some(match => match.id === id)
}

function links(matches: Array<any>) {
    let links = [
        { name: "Dashboard", href: "/", id: "routes/_index", icon: Home },
        { name: "API Keys", href: "/api-keys", id: "routes/api-keys+/index", icon: LockKeyhole },
        { name: 'Usage', href: '/usage', id: 'routes/usage+/index', icon: LineChart },
    ]

    return links.map((link, index) => {
        return (
            <TooltipProvider key={index}>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Link
                            to={link.href}
                            className={cn("flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8", linkIsActive(matches, link.id) && 'bg-accent text-accent-foreground')}
                        >
                            <link.icon className="h-5 w-5" />
                            <span className="sr-only">{link.name}</span>
                        </Link>
                    </TooltipTrigger>
                    <TooltipContent side="right">{link.name}</TooltipContent>
                </Tooltip>
            </TooltipProvider>
        )
    })
}

function userAvatar() {
    const data = useRouteLoaderData<typeof rootLoader>('root')

    // get first letter of name or use email to generate initials
    let name;
    if (data?.user?.name) {
        name = data.user.name;
    }
    else {
        name = data?.user?.email;
    }
    let firstLetter = name.charAt(0).toUpperCase();

    return (
        <Button
            variant="outline"
            size="icon"
            className="overflow-hidden rounded-full"
        >
            <Avatar className='h-8 w-8'>
                <AvatarFallback>{firstLetter}</AvatarFallback>
            </Avatar>
        </Button>
    )
}

export function Dashboard() {
    const submit = useSubmit()
    const formRef = useRef<HTMLFormElement>(null);
    const matches = useMatches();
    return (
        <div className="flex min-h-screen w-full flex-col bg-muted/40">
            <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
                <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
                    <Link
                        to="/"
                        className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base"
                    >
                        <Package2 className="h-4 w-4 transition-all group-hover:scale-110" />
                        <span className="sr-only">SavvyIQ</span>
                    </Link>
                    {links(matches)}
                </nav>
                <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Link
                                    to="/settings/profile"
                                    className={cn("flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8", linkIsActive(matches, "routes/settings+/_layout") && 'bg-accent text-accent-foreground')}
                                >
                                    <Settings className="h-5 w-5" />
                                    <span className="sr-only">Settings</span>
                                </Link>
                            </TooltipTrigger>
                            <TooltipContent side="right">Settings</TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                </nav>
            </aside>
            <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
                <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
                    <Sheet>
                        <SheetTrigger asChild>
                            <Button size="icon" variant="outline" className="sm:hidden">
                                <PanelLeft className="h-5 w-5" />
                                <span className="sr-only">Toggle Menu</span>
                            </Button>
                        </SheetTrigger>
                        <SheetContent side="left" className="sm:max-w-xs">
                            <nav className="grid gap-6 text-lg font-medium">
                                <Link
                                    to="/"
                                    className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
                                >
                                    <Package2 className="h-5 w-5 transition-all group-hover:scale-110" />
                                    <span className="sr-only">SavvyIQ</span>
                                </Link>
                                <Link
                                    to="/"
                                    className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
                                >
                                    <Home className="h-5 w-5" />
                                    Dashboard
                                </Link>
                                <Link
                                    to="/api-keys"
                                    className="flex items-center gap-4 px-2.5 text-foreground"
                                >
                                    <LockKeyhole className="h-5 w-5" />
                                    API Keys
                                </Link>
                                <Link
                                    to="/settings/profile"
                                    className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
                                >
                                    <LineChart className="h-5 w-5" />
                                    Settings
                                </Link>
                            </nav>
                        </SheetContent>
                    </Sheet>
                    {/* <Breadcrumb className="hidden md:flex">
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbLink asChild>
                                    <Link href="#">Dashboard</Link>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbLink asChild>
                                    <Link href="#">Orders</Link>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                        </BreadcrumbList>
                    </Breadcrumb> */}
                    <div className="relative ml-auto flex-1 md:grow-0">
                        {/* <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                            type="search"
                            placeholder="Search..."
                            className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                        /> */}
                    </div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            {userAvatar()}
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>My Account</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem>
                                <Link to="/settings/profile" >
                                    Settings
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem>

                                <a href="https://docs.savvyiq.ai/api-reference" target='_blank'>
                                    API Reference
                                </a>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                                asChild
                                // this prevents the menu from closing before the form submission is completed
                                onSelect={event => {
                                    event.preventDefault()
                                    submit(formRef.current)
                                }}
                            >
                                <Form action="/logout" method="POST" ref={formRef}>
                                    <span>Logout</span>
                                </Form>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </header>
                <Outlet />
            </div>
        </div>
    )
}
