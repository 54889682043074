import { getFormProps, useForm } from '@conform-to/react';
import { parseWithZod } from '@conform-to/zod';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type DataFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction } from
'@remix-run/node';
import {
  Form,
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useFetchers,
  useLoaderData,
  useMatches,
  useSubmit } from
'@remix-run/react';
// import { withSentry } from '@sentry/remix'
import { useRef } from 'react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { ErrorList } from './components/forms.tsx';
import { EpicProgress } from './components/progress-bar.tsx';
import { SearchBar } from './components/search-bar.tsx';
import { EpicToaster } from './components/toaster.tsx';
import { Button } from './components/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger } from
'./components/ui/dropdown-menu.tsx';
import { Icon, href as iconsHref } from './components/ui/icon.tsx';
// import globalStyleSheetUrl from './styles/globals.css'
import fontStyleSheetUrl from './styles/font.css';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getUserId, logout } from './utils/auth.server.ts';
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx';
import { prisma } from './utils/db.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl, getUserImgSrc } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { useRequestInfo } from './utils/request-info.ts';
import { type Theme, setTheme, getTheme } from './utils/theme.server.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';
import { useOptionalUser, useUser } from './utils/user.ts';
import { logger } from './utils/logger.ts';
import { Dashboard } from './partials/main.tsx';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  // { rel: 'preload', href: globalStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  { rel: 'stylesheet', href: fontStyleSheetUrl },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'SavvyIQ' : 'Error | SavvyIQ' },
  { name: 'description', content: `SavvyIQ Application` }];

};

export async function loader({ request }: DataFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUniqueOrThrow({
      select: {
        id: true,
        name: true,
        username: true,
        email: true,
        image: { select: { id: true } },
        roles: {
          select: {
            role: {
              select: {
                name: true,
                permissions: {
                  select: { entity: true, action: true, access: true }
                }
              }
            }

          }
        },
        account: {
          select: {
            accountId: true,
            account: {
              select: {
                accountOption: {
                  select: {
                    activated: true
                  }
                }
              }
            }
          }
        }
      },
      where: { id: userId }
    }),
    { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;
  if (userId && !user) {
    logger.error({ userId }, 'something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      honeyProps
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark'])
});

export async function action({ request }: DataFunctionArgs) {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: ThemeFormSchema
  });
  if (submission.status !== 'success') {
    return json(
      { result: submission.reply() },
      { status: submission.status === 'error' ? 400 : 200 }
    );
  }
  if (!submission.value) {
    return json(({ status: 'error', submission } as const), { status: 400 });
  }
  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setTheme(theme) }
  };
  return json({ success: true, submission }, responseInit);
}

function Document({
  children,
  nonce,
  theme = 'light',
  env = {}





}: {children: React.ReactNode;nonce: string;theme?: Theme;env?: Record<string, string>;}) {
  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Links />
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function shouldUseMainLayout() {
  const pages = ['routes/_index', 'routes/settings+/team'];
  const matches = useMatches();

  return matches.some((m) => pages.includes(m.id));
}


function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const user = useOptionalUser();
  const theme = useTheme();
  const matches = useMatches();
  const isOnSearchPage = matches.find((m) => m.id === 'routes/users+/index');
  const mainLayout = shouldUseMainLayout();
  const searchBar = isOnSearchPage ? null : <SearchBar status="idle" />;

  // if (user) {
  // 	return (
  // 		<Document nonce={nonce} theme={theme} env={data.ENV}>
  // 			<div
  // 				// style={montserrat.style}
  // 				className="h-screen overflow-hidden flex"
  // 			>
  // 				<div className="w-200 h-screen relative">
  // 					{/* <Sidebar /> */}
  // 				</div>
  // 				<div className="flex-1">
  // 					<main className="overflow-auto">
  // 						<Outlet />
  // 					</main>
  // 				</div>
  // 			</div>
  // 			<EpicToaster toast={data.toast} />
  // 			<EpicProgress />
  // 		</Document>
  // 	)
  // }
  if (user?.account?.account?.accountOption?.activated) {
    return (
      <Document nonce={nonce} theme={theme} env={data.ENV}>
				{/* <Outlet /> */}
				<Dashboard />
				<EpicToaster toast={data.toast} />
				<EpicProgress />
			</Document>);

  }

  return (
    <Document nonce={nonce} theme={theme} env={data.ENV}>
			<div className="flex h-screen flex-col justify-between">
				{/* <header className="container py-6">
          <nav>
          	<div className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
          		<Link to="/">
          			<div className="font-bold">UI</div>
          		</Link>
          		<div className="flex items-center gap-10">
          			{user ? (
          				<UserDropdown />
          			) : (
          				<Button asChild variant="default" size="sm">
          					<Link to="/login">Log In</Link>
          				</Button>
          			)}
          		</div>
          		<div className="block w-full sm:hidden">{searchBar}</div>
          	</div>
          </nav>
          </header> */}

				<div className="flex-1">
					<Outlet />
				</div>

				{/* <div className="container flex justify-between pb-5">
          <Link to="/">
          	<div className="font-bold">UI</div>
          </Link>
          <ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} />
          </div> */}
			</div>
			<EpicToaster toast={data.toast} />
			<EpicProgress />
		</Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>);

}

// export default withSentry(AppWithProviders)
export default AppWithProviders;

function UserDropdown() {
  const user = useUser();
  const submit = useSubmit();
  const formRef = useRef<HTMLFormElement>(null);
  return (
    <DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button asChild variant="secondary">
					<Link
            to={`/users/${user.username}`}
            // this is for progressive enhancement
            onClick={(e) => e.preventDefault()}
            className="flex items-center gap-2">

						{/* <img
              className="h-8 w-8 rounded-full object-cover"
              alt={user.name ?? user.username}
              src={getUserImgSrc(user.image?.id)}
              /> */}
						<span className="text-body-sm font-bold">
							{user.name ?? user.username}
						</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.username}`}>
							<Icon className="text-body-md" name="avatar">
								Profile
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						{/* <Link prefetch="intent" to={`/users/${user.username}/notes`}>
              <Icon className="text-body-md" name="pencil-2">
              	Notes
              </Icon>
              </Link> */}
						<Link prefetch="intent" to={`/setup`}>
							<Icon className="text-body-md" name="pencil-2">
								Setup
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem
            asChild
            // this prevents the menu from closing before the form submission is completed
            onSelect={(event) => {
              event.preventDefault();
              submit(formRef.current);
            }}>

						<Form action="/logout" method="POST" ref={formRef}>
							<Icon className="text-body-md" name="exit">
								<button type="submit">Logout</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>);

}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }
  return requestInfo.userPrefs.theme ?? hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema
    });

    if (submission.status === 'success') {
      return submission.value.theme;
    }
  }
}

function ThemeSwitch({ userPreference }: {userPreference?: Theme | null;}) {
  const fetcher = useFetcher<typeof action>();

  const [form] = useForm({
    id: 'theme-switch',
    lastResult: fetcher.data?.result
  });

  const optimisticMode = useOptimisticThemeMode();
  const mode = optimisticMode ?? userPreference ?? 'system';
  const nextMode =
  mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system';
  const modeLabel = {
    light:
    <Icon name="sun">
				<span className="sr-only">Light</span>
			</Icon>,

    dark:
    <Icon name="moon">
				<span className="sr-only">Dark</span>
			</Icon>,

    system:
    <Icon name="laptop">
				<span className="sr-only">System</span>
			</Icon>

  };

  return (
    <fetcher.Form method="POST" {...getFormProps(form)}>
			<input type="hidden" name="theme" value={nextMode} />
			<div className="flex gap-2">
				<button
          type="submit"
          className="flex h-8 w-8 cursor-pointer items-center justify-center">

					{modeLabel[mode]}
				</button>
			</div>
		</fetcher.Form>);

}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}